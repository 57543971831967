<template>
  <div class="home">
    <div class="illustrations">
      <img
        src="@/assets/home/vector.svg"
        alt="Vector illustration in background"
        class="vector"
      />
      <img
        src="@/assets/home/home-illustration.jpg"
        alt="Image illustration in background"
        class="home-illustration"
      />
      <img src="@/assets/home/card.svg" alt="Card illustration" class="card" />
      <img
        src="@/assets/home/feather.svg"
        alt="Feather illustration in background"
        class="feather"
      />
    </div>
    <div class="content">
      <h1>{{ homeTexts.heading }}</h1>
      <p class="description">{{ homeTexts.description }}</p>
      <div class="buttons">
        <button class="btn btn-primary" @click="loginToUlet">
          Prihlásiť sa
        </button>
        <button class="btn btn-secondary" @click="contactUs">
          Chcem sa dozvedieť viac
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import homeTexts from "@/assets/home/home.json";

export default {
  name: "HomeView",
  data() {
    return {
      homeTexts: homeTexts,
    };
  },
  methods: {
    loginToUlet() {
      window.open("https://app.ulet.app", "_blank");
    },
    contactUs() {
      window.location.href = "mailto:ulet@info.app";
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: #505db6;

  .illustrations {
    position: static;
    z-index: 1;

    .vector {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      min-height: 100vh;
      object-fit: cover;
      z-index: 3;
    }

    .home-illustration {
      position: absolute;
      right: -20%;
      width: 70%;
      min-height: 100vh;
      object-fit: cover;
      -webkit-mask-image: linear-gradient(
        270deg,
        transparent 0%,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        transparent 100%
      );
      z-index: 4;
    }

    .card {
      position: absolute;
      bottom: -125px;
      left: 0;
      width: 250px;
      transform: rotate(45deg);
      z-index: 4;
    }
    .feather {
      position: absolute;
      top: 240px;
      left: 240px;
      width: 80px;
      z-index: 4;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: calc(1360px - 40px);
    min-height: calc(100vh - 40px);
    z-index: 5;

    .description {
      width: 100%;
      max-width: 700px;
    }

    .buttons {
      display: flex;
      gap: 20px;
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    .illustrations {
      .home-illustration {
        display: none;
      }
    }

    .content {
      .buttons {
        flex-direction: column;
      }
    }
  }
}
</style>
