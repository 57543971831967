<template>
  <div class="application">
    <img
      class="card-one"
      src="@/assets/application/card-1.svg"
      alt="Card 1 vector"
    />
    <img
      class="card-two"
      src="@/assets/application/card-2.svg"
      alt="Card 2 vector"
    />
    <div class="content">
      <h1>Ukážky z aplikácie</h1>
      <Splide
        :options="{
          rewind: true,
          gap: 20,
        }"
        aria-label="My Favorite Images"
        style="width: calc(100% - 40px)"
      >
        <SplideSlide v-for="(item, index) in texts" :key="item.image">
          <div class="slide">
            <div class="image">
              <img
                :src="images[index]"
                :alt="'Obrázok ' + index + ' prezentácie'"
              />
            </div>
            <p>{{ item.description }}</p>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>
<script>
import texts from "@/assets/application/application.json";
import image1 from "@/assets/application/historia-bodov.jpg";
import image2 from "@/assets/application/galeria-uloh.jpg";
import image3 from "@/assets/application/galeria-benefitov.jpg";
import image4 from "@/assets/application/galeria-kacerov.jpg";

export default {
  name: "ApplicationView",
  data() {
    return {
      texts: texts,
      images: [image1, image2, image3, image4],
    };
  },
};
</script>
<style lang="scss" scoped>
.application {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #50b6b0 0%, #4e5db8 100%);
  overflow: hidden;

  .card-one {
    position: absolute;
    top: 300px;
    left: -80px;
    width: 200px;
    transform: rotate(45deg);
  }

  .card-two {
    position: absolute;
    top: 100px;
    right: -80px;
    width: 200px;
    transform: rotate(-45deg);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 20px;
    max-width: calc(1360px - 40px);
    width: calc(100% - 40px);
    z-index: 3;

    h1 {
      margin-top: 40px;
    }
  }

  .slide {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 30px;
    background: #2e3ea6;
    //box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);

    .image {
      width: 100%;
      border-radius: 30px;
      height: 540px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .text {
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .application {
    .slide {
      .image {
        height: 300px;

        img {
          height: 100%;
          object-position: top;
          object-fit: cover;
        }
      }
    }
  }
}
</style>
