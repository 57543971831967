<template>
  <div class="main-container">
    <Navigation
      :selectedItem="$store.getters.currentPartPageView"
      @select-item="selectNavItem"
    ></Navigation>
    <router-view></router-view>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation/Navigation.vue";
import store from "@/store/index";

export default {
  components: {
    Navigation,
  },
  methods: {
    selectNavItem(item) {
      store.dispatch("setCurrentPart", item);
      store.dispatch("setCurrentPartView", item);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "SomeTimeLater";
  src: url(./assets/fonts/SomeTimeLater.otf) format("opentype");
}

@font-face {
  font-family: "Avenir";
  src: url(./assets/fonts/AvenirRegular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir";
  src: url(./assets/fonts/AvenirBold.otf) format("opentype");
  font-weight: 700;
}

body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

button {
  background: none;
  border: none;
}

h1 {
  padding: 0;
  margin: 0;
  font-family: "SomeTimeLater";
  font-weight: 400;
  font-size: 64px;
  color: var(--white);
}

p {
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: var(--white);
}

:root {
  --green-500: #28b775;
  --green-600: #16965b;
  --orange-500: #f15a29;
  --orange-600: #de5411;
  --light-gray: #e0e0e0;
  --dark-gray: #3d3d3d;
  --white: #fff;
  --border-radius: 30px;
  --box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15);
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  div {
    width: 100%;
  }
}

.btn {
  padding: 10px 20px;
  border-radius: 10000px;
  font-family: "Avenir", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: var(--white);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.btn-nav {
  color: var(--dark-gray);
}

.btn-nav-active {
  color: var(--white);
  background-color: var(--green-500);
  box-shadow: var(--box-shadow);
}

.btn-primary {
  background-color: var(--green-500);
  box-shadow: var(--box-shadow);
}

.btn-secondary {
  background-color: var(--orange-500);
  box-shadow: var(--box-shadow);
}

.btn-primary:hover {
  background-color: var(--green-600);
}

.btn-secondary:hover {
  background-color: var(--orange-600);
}

.btn-primary:hover,
.btn-secondary:hover {
  transform: scale(1.1);
}
@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 48px;
    line-height: 50px;
  }
  P {
    font-size: 14px;
    line-height: 19px;
  }
}
</style>
