const NavigationEnum = Object.freeze({
  Home: "home",
  Flight: "flight",
  Values: "values",
  Personas: "personas",
  Application: "application",
  References: "references",
  Contact: "contact",
});

export default NavigationEnum;
