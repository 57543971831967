<template>
  <div class="navigation">
    <div class="desktop">
      <a href="#"><img src="../../assets/logo.svg" alt="Úlet logo svg" /></a>
      <nav class="nav-desktop">
        <button
          class="btn btn-nav"
          :class="{ 'btn-nav-active': selectedItem === navigationEnum.Home }"
          @click="setNavItem(navigationEnum.Home)"
        >
          Domov
        </button>
        <button
          class="btn btn-nav"
          :class="{ 'btn-nav-active': selectedItem === navigationEnum.Flight }"
          @click="setNavItem(navigationEnum.Flight)"
        >
          Úlet
        </button>
        <button
          class="btn btn-nav"
          :class="{ 'btn-nav-active': selectedItem === navigationEnum.Values }"
          @click="setNavItem(navigationEnum.Values)"
        >
          Hodnoty
        </button>
        <button
          class="btn btn-nav"
          :class="{
            'btn-nav-active': selectedItem === navigationEnum.Personas,
          }"
          @click="setNavItem(navigationEnum.Personas)"
        >
          Určenie
        </button>
        <button
          class="btn btn-nav"
          :class="{
            'btn-nav-active': selectedItem === navigationEnum.Application,
          }"
          @click="setNavItem(navigationEnum.Application)"
        >
          Aplikácia
        </button>
        <button
          class="btn btn-nav"
          :class="{
            'btn-nav-active': selectedItem === navigationEnum.References,
          }"
          @click="setNavItem(navigationEnum.References)"
        >
          Referencie
        </button>
        <button
          class="btn btn-nav"
          :class="{ 'btn-nav-active': selectedItem === navigationEnum.Contact }"
          @click="setNavItem(navigationEnum.Contact)"
        >
          Kontakt
        </button>
      </nav>
      <div class="login-container login">
        <button class="btn btn-primary icon-button login">
          Prihlásiť sa
          <img
            src="../../assets/arrow-down.svg"
            alt="Arrow down icon"
            class="login-button-icon"
          />
        </button>
        <div class="popover">
          <a href="https://app.ulet.app" target="_blank">Formálne</a>
          <a href="https://informal.ulet.app" target="_blank">Neformálne</a>
        </div>
      </div>

      <button class="hamburger" @click="mobileActive = true">
        <img src="../../assets/hamburger.svg" alt="Hamburger menu icon" />
      </button>
    </div>
    <div class="mobile" :class="{ 'mobile-active': mobileActive }">
      <div class="overlay" @click="mobileActive = false"></div>
      <div class="card" :class="{ 'card-active': mobileActive }">
        <button class="close-nav" @click="mobileActive = false">
          <img src="../../assets/close-nav.svg" alt="Hamburger menu icon" />
        </button>
        <div class="mobile-nav-items">
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Home,
            }"
            @click="setNavItem(navigationEnum.Home)"
          >
            Domov
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Flight,
            }"
            @click="setNavItem(navigationEnum.Flight)"
          >
            Úlet
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Values,
            }"
            @click="setNavItem(navigationEnum.Values)"
          >
            Hodnoty
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Personas,
            }"
            @click="setNavItem(navigationEnum.Personas)"
          >
            Určenie
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Application,
            }"
            @click="setNavItem(navigationEnum.Application)"
          >
            Aplikácia
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.References,
            }"
            @click="setNavItem(navigationEnum.References)"
          >
            Referencie
          </button>
          <button
            class="btn"
            :class="{
              'btn-nav-active': selectedItem === navigationEnum.Contact,
            }"
            @click="setNavItem(navigationEnum.Contact)"
          >
            Kontakt
          </button>

          <div class="login-container">
            <button class="btn btn-primary icon-button">
              Prihlásiť sa
              <img
                src="../../assets/arrow-down.svg"
                alt="Arrow down icon"
                class="login-button-icon"
              />
            </button>
            <div class="popover">
              <a href="https://app.ulet.app" target="_blank">Formálne</a>
              <a href="https://informal.ulet.app" target="_blank">Neformálne</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavigationEnum from "@/enums/NavigationEnum.js";

export default {
  name: "Navigation",
  props: {
    selectedItem: NavigationEnum,
  },
  data() {
    return {
      mobileActive: false,
      navigationEnum: NavigationEnum,
    };
  },
  methods: {
    setNavItem(item) {
      this.mobileActive = false;
      this.$emit("selectItem", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: calc(1360px);
  z-index: 999999;

  a img {
    width: 60px;
    height: 60px;
  }

  .desktop {
    display: flex;
    padding: 20px;
    gap: 25px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);

    nav {
      padding: 10px;
      border-radius: 10000px;
      background-color: var(--light-gray);
    }

    .hamburger {
      display: none;
      cursor: pointer;
    }
  }

  .mobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .overlay {
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.3);
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: absolute;
      top: 0;
      right: 0;
      padding: 20px;
      width: 100%;
      max-width: 300px;
      height: 100vh;
      background: rgba(80, 93, 182);
      transition: all 0.3s ease-in;
      box-shadow: rgba(0, 0, 0, 0.15) -5px 0px 5px 2px;
      transform: translateX(200px);

      .close-nav {
        width: 20px;
        cursor: pointer;
      }

      .mobile-nav-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-top: 40px;
        width: 100%;

        .btn {
          color: var(--white);
        }
      }
    }

    .card-active {
      transform: translateX(0px);
    }
  }

  .mobile-active {
    display: block;
  }

  .btn-primary:hover,
  .btn-secondary:hover {
    transform: scale(1);
  }

  .icon-button {
    display: flex;
    gap: 8px;
    img {
      width: 16px;
      transition: all 0.3s ease-in-out;
    }
  }

  .login-container {
    position: relative;
    width: fit-content;
  }

  .login-container:focus-within {
    .popover {
      opacity: 1;
      visibility: visible;
    }
    .login-button-icon {
      transform: rotateX(180deg);
    }
  }
  .popover {
    position: absolute;
    top: 45px;
    left: 9px;
    display: flex;
    visibility: hidden;
    width: fit-content;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: var(--green-500);
    box-shadow: var(--box-shadow);
    transition: all 0.3s ease-in-out;
  }

  .popover a {
    display: block;
    padding: 10px 20px;
    width: calc(100% - 40px);
    border-radius: 10px;
    font-family: "Avenir", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  .popover a:hover {
    background-color: var(--green-600);
  }
}

@media only screen and (max-width: 1024px) {
  .nav-desktop,
  .login {
    display: none;
  }

  .navigation {
    .desktop {
      .hamburger {
        display: block;
      }
    }
  }
}
</style>
