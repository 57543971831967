<template>
  <div :ref="navigationEnum.Home" class="layer">
    <HomeView></HomeView>
  </div>
  <div :ref="navigationEnum.Flight" class="layer">
    <FlightView></FlightView>
  </div>
  <div :ref="navigationEnum.Values" class="layer-parallax">
    <ValuesView></ValuesView>
  </div>
  <div :ref="navigationEnum.Personas" class="layer">
    <PersonasView></PersonasView>
  </div>
  <div :ref="navigationEnum.Application" class="layer">
    <ApplicationView></ApplicationView>
  </div>
  <div :ref="navigationEnum.References" class="layer">
    <ReferenciesView></ReferenciesView>
  </div>
  <div :ref="navigationEnum.Contact" class="layer">
    <ContactView></ContactView>
  </div>
</template>
<script>
import HomeView from "@/views/HomeView.vue";
import FlightView from "@/views/FlightView.vue";
import ValuesView from "@/views/ValuesView.vue";
import PersonasView from "@/views/PersonasView.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import ReferenciesView from "@/views/ReferenciesView.vue";
import ContactView from "@/views/ContactView.vue";
import { mapState } from "vuex";
import NavigationEnum from "@/enums/NavigationEnum";
import store from "@/store/index";

export default {
  name: "ContentView",
  components: {
    HomeView,
    FlightView,
    ValuesView,
    PersonasView,
    ApplicationView,
    ReferenciesView,
    ContactView,
  },
  data() {
    return {
      navigationEnum: NavigationEnum,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    navigateTo(part) {
      this.$refs[part].scrollIntoView({ behavior: "smooth" });
    },
    handleScroll() {
      const scrollTop = window.scrollY;

      const section1Top = this.$refs[this.navigationEnum.Home].offsetTop;
      const section2Top = this.$refs[this.navigationEnum.Flight].offsetTop;
      const section3Top = this.$refs[this.navigationEnum.Values].offsetTop;
      const section4Top = this.$refs[this.navigationEnum.Personas].offsetTop;
      const section5Top = this.$refs[this.navigationEnum.Application].offsetTop;
      const section6Top = this.$refs[this.navigationEnum.References].offsetTop;
      const section7Top = this.$refs[this.navigationEnum.Contact].offsetTop;

      if (scrollTop >= section1Top && scrollTop < section2Top * 0.9) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Home);
      } else if (
        scrollTop >= section2Top * 0.9 &&
        scrollTop < section3Top * 0.9
      ) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Flight);
      } else if (
        scrollTop >= section3Top * 0.9 &&
        scrollTop < section4Top * 0.98
      ) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Values);
      } else if (
        scrollTop >= section4Top * 0.98 &&
        scrollTop < section5Top * 0.98
      ) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Personas);
      } else if (
        scrollTop >= section5Top * 0.99 &&
        scrollTop < section6Top * 0.99
      ) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Application);
      } else if (
        scrollTop >= section6Top * 0.99 &&
        scrollTop < section7Top * 0.94
      ) {
        store.dispatch("setCurrentPartView", this.navigationEnum.References);
      } else if (scrollTop >= section7Top * 0.94) {
        store.dispatch("setCurrentPartView", this.navigationEnum.Contact);
      }
    },
  },
  computed: {
    ...mapState(["currentPart"]),
  },
  watch: {
    currentPart: {
      handler(newPart) {
        this.navigateTo(newPart);
      },
      deep: true,
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.layer {
  z-index: 20;
}

.layer-parallax {
  z-index: 9;
}

.background-thing {
  width: 100%;
  height: 100vh;
  background-color: black;
}
</style>
