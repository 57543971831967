<template>
  <div class="contact">
    <div class="content">
      <h1>Kontaktujte nás</h1>
      <div class="info">
        <div class="item">
          <img src="@/assets/contact/map-marker.svg" alt="Map marker icon" />
          <div class="text">
            <p>Bottova 15, 054 01 Levoča, Slovenská republika</p>
          </div>
        </div>

        <!--<div class="item">
          <img src="@/assets/contact/phone.svg" alt="Phone icon" />
          <div class="text">
            <p>
              <a href="tel:+421908908908">+421 908 908 908</a>
            </p>
          </div>
        </div>-->

        <div class="item">
          <img src="@/assets/contact/mail.svg" alt="Mail icon" />
          <div class="text">
            <p>
              <a href="https://kpkc.sk/kontakt/"
                >Katolícke pedagogické a katechetické centrum</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="supported">
        <p>Podporili nás...</p>
        <div class="companies">
          <a href="https://kpkc.sk" target="_blank">
            <img src="@/assets/contact/kpkc.png" alt="KPKC logo" />
          </a>
          <a href="https://www.ozviac.sk" target="_blank">
            <img src="@/assets/contact/ozviac.png" alt="O.Z. VIAC png" />
          </a>
          <a href="https://www.erasmusplus.sk" target="_blank">
            <img src="@/assets/contact/erasmus+.png" alt="Erasmus+ logo" />
          </a>

          <a href="https://www.ludskezdroje.gov.sk" target="_blank">
            <img
              src="@/assets/contact/zdroje.png"
              alt="Operačný program zdroje logo"
            />
          </a>
        </div>
      </div>
      <div class="illustration">
        <div class="background"></div>
        <img src="@/assets/contact/duck.png" alt="Duck illustration" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactView",
};
</script>
<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  width: calc(100% - 40px);
  background: #253d38;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    max-width: calc(1360px);
    width: calc(100%);

    .info {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        gap: 30px;
        .text {
          p {
            a {
              color: #fff;
            }
          }
        }
      }
    }

    .supported {
      .companies {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: 30px;
        padding: 10px;
        width: fit-content;
        background-color: var(--white);

        img {
          width: 90px;
        }
      }
    }
  }

  .illustration {
    .background {
      position: absolute;
      top: 50px;
      right: -20px;
      width: 450px;
      height: 450px;
      border-radius: 698px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #5c957d 0%,
        rgba(37, 61, 56, 1) 100%
      );
    }

    img {
      position: absolute;
      bottom: -50px;
      right: 50px;
      width: 300px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .contact {
    .content {
      .illustration {
        display: none;
      }
    }
  }
}
</style>
