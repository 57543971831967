<template>
  <div class="personas">
    <img
      class="background"
      src="@/assets/personas/background.jpg"
      alt="Background of personas"
    />
    <div class="content">
      <div class="student">
        <div class="texts">
          <h1>{{ texts.student.heading }}</h1>
          <p>{{ texts.student.description }}</p>
        </div>
        <img
          src="@/assets/personas/student_duck.png"
          alt="Student duck illustration"
        />
      </div>
      <div class="teacher">
        <div class="texts">
          <h1>{{ texts.teacher.heading }}</h1>
          <p>{{ texts.teacher.description }}</p>
        </div>
        <img
          src="@/assets/personas/teacher_duck.png"
          alt="Teacher duck illustration"
        />
      </div>
    </div>
  </div>
</template>
<script>
import texts from "@/assets/personas/personas.json";
export default {
  name: "PersonasView",
  data() {
    return {
      texts: texts,
    };
  },
};
</script>
<style lang="scss" scoped>
.personas {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  min-height: 110vh;
  overflow: hidden;
  background: #000;
}

.background {
  position: absolute;
  width: 110%;
  min-height: 120vh;
  filter: blur(15px);
  object-fit: cover;
}

.content {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 20px;
  max-width: calc(1360px - 40px);
  width: calc(100% - 40px);
  min-height: 110vh;
  z-index: 3;

  .teacher,
  .student {
    display: flex;
    gap: 30px;

    .texts {
      text-align: justify;
    }

    img {
      width: auto;
      width: 150px;
      transition: all 0.3s ease-in-out;
    }
    img:hover {
      scale: (1.2);
    }
  }
  .teacher {
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 1024px) {
  .personas {
    .background {
      min-height: 150vh;
    }
    .content {
      min-height: 150vh;
      flex-direction: column;
      justify-content: center;
      padding: 40px 20px;
      .teacher,
      .student {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
