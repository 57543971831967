<template>
  <div class="referencies">
    <div class="content">
      <div class="stars">
        <img src="@/assets/referencies/stars.svg" alt="Star image" />
        <h1>Hodnotenia<br />zákazníkov</h1>
      </div>
      <div class="items">
        <Splide
          :options="options"
          aria-label="My Favorite Images"
          style="width: calc(100% - 6em)"
        >
          <SplideSlide v-for="item in texts" :key="item.name">
            <div class="slide">
              <p class="name">{{ item.name }}</p>
              <p class="description">{{ item.text }}</p>
              <div class="rating">
                <img
                  v-for="star in item.rating"
                  src="@/assets/referencies/star.svg"
                  alt="Star icon"
                  :key="star"
                />
              </div>
              <span class="divider"></span>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  </div>
</template>
<script>
import texts from "@/assets/referencies/referencies.json";
export default {
  name: "ReferenciesView",
  data() {
    return {
      texts: texts,
      options: {
        drag: "free",
        focus: "center",
        perPage: 3,
        rewind: true,
        gap: "30px",
        breakpoints: {
          1300: {
            perPage: 2,
            gap: ".7rem",
          },
          600: {
            perPage: 1,
            gap: ".7rem",
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.referencies {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, #0a0d10 0%, #33302c 50.52%, #080b0e 100%);
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: relative;
    padding: 20px;
    max-width: calc(1360px - 40px);
    width: calc(100% - 40px);

    .stars {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      img {
        display: block;
        width: 100%;
        max-width: 988px;
      }

      h1 {
        position: absolute;
        left: calc(50% - 150px);
        margin-top: 80px;
        background: -webkit-linear-gradient(
          101deg,
          #f4c36f 0.08%,
          #ee8c1c 100.53%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 30px;
      border: 1px solid #1c1d20;
      padding: 25px;
      width: calc(100% - 50px);
      background: #1a1b1e;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .name {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
      }

      .description {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
      }

      .rating {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .divider {
        margin-top: 20px;
        width: 40px;
        height: 1px;
        flex-shrink: 0;
        background: #f1ae50;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .referencies {
    .content {
      .stars {
        img {
          display: none;
        }

        h1 {
          margin-top: initial;
          position: initial;
        }
      }
    }
  }
}
</style>
