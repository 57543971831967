<template>
  <div class="flight">
    <div class="content">
      <div class="cards">
        <span class="description">
          <h1>{{ texts.heading }}</h1>
          <p>{{ texts.description }}</p>
        </span>
        <div class="image">
          <span class="cards-background"></span>
          <img
            src="@/assets/flight/karticky.png"
            alt="Ilustrácia kartičiek"
            class="cards-illustration"
          />
        </div>
      </div>

      <iframe
        class="video"
        src="https://www.youtube.com/embed/tr7nPTUrxvo?si=rEaSnAzUC9kfDznn"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
import texts from "@/assets/flight/flight.json";

export default {
  name: "FlightView",
  data() {
    return {
      texts: texts,
    };
  },
};
</script>
<style lang="scss" scoped>
.flight {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  background: linear-gradient(180deg, #10131c 0%, #222940 50.52%, #10131c 100%);
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 20px;
    width: calc(100% - 40px);
    max-width: calc(1360px - 40px);
    min-height: calc(100vh - 100px);
    z-index: 5;

    .cards {
      display: flex;
      align-items: center;
      gap: 32px;
      .image {
        display: flex;
        flex: 0.5;
        align-items: center;
        justify-content: center;
        position: relative;

        .cards-illustration {
          width: 511px;
          transition: all 0.3s ease-in-out;
          z-index: 2;
        }

        .cards-background {
          position: absolute;
          width: 698px;
          height: 698px;
          border-radius: 698px;
          background: radial-gradient(
            50% 50% at 50% 50%,
            #2f5576 44.06%,
            rgba(21, 26, 38, 0) 100%
          );
        }

        .cards-illustration:hover {
          transform: scale(110%) rotate(10deg);
        }
      }
      .description {
        flex: 0.5;
        z-index: 2;
      }
    }
    .video {
      margin-top: 30px;
      width: 100%;
      height: 600px;
      z-index: 2;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .flight {
    .content {
      .cards {
        flex-direction: column;

        .image {
          .cards-illustration {
            width: 100%;
            max-width: 400px;
          }
        }
      }

      .video {
        height: 300px;
      }
    }
  }
}
</style>
