<template>
  <div ref="divStart" class="values">
    <img
      class="background"
      src="@/assets/values/background.jpg"
      alt="Background values img"
      ref="background"
    />
    <div class="duck-box">
      <img
        ref="duck"
        class="duck"
        src="@/assets/values/kacka.svg"
        alt="Ilustrácia kačky"
      />
    </div>
    <div class="content" :class="{ 'show-content': showContent }">
      <div class="texts">
        <h1>{{ texts.heading }}</h1>
        <p
          v-for="(item, index) in texts.points"
          :class="activeItems[index] ? 'visible' : 'invisible'"
          :key="index"
        >
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import texts from "@/assets/values/values.json";
export default {
  name: "ValuesView",
  data() {
    return {
      texts: texts,
      showContent: true,
      activeItems: [],
    };
  },
  methods: {
    handleParallaxScroll() {
      const duck = this.$refs["duck"];
      const divStart = this.$refs["divStart"].offsetTop;
      const background = this.$refs["background"];
      const scrollY = window.scrollY;
      const spaceForTexts = 500;

      const maxSize = 120;
      if (divStart - 700 < scrollY) {
        const size = (scrollY - divStart) / (maxSize - 100);
        if (size < 67) {
          duck.style.transform = "scale(" + (120 - size) / 100 + ")";
          background.style.transform = "scale(" + (1.4 + size / 300) + ")";
        }

        if (divStart - 1000 <= scrollY && scrollY < divStart + spaceForTexts) {
          this.activeItems[0] = true;
          this.activeItems[1] = false;
        } else if (
          divStart + spaceForTexts <= scrollY &&
          scrollY < divStart + spaceForTexts * 2
        ) {
          this.activeItems[0] = false;
          this.activeItems[1] = true;
          this.activeItems[2] = false;
        } else if (
          divStart + spaceForTexts * 2 <= scrollY &&
          scrollY < divStart + spaceForTexts * 3
        ) {
          this.activeItems[1] = false;
          this.activeItems[2] = true;
          this.activeItems[3] = false;
        } else if (divStart + spaceForTexts * 3 <= scrollY) {
          this.activeItems[2] = false;
          this.activeItems[3] = true;
        }
      }
    },
    fillActiveItem() {
      this.texts.points.map((item, index) => {
        this.activeItems[index] = false;
      });
    },
    getActiveItem(index) {
      return this.activeItems[index];
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleParallaxScroll);
  },

  created() {
    this.fillActiveItem();
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleParallaxScroll);
  },
};
</script>
<style lang="scss" scoped>
.values {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 300vh;
  overflow: hidden;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }

  .duck-box {
    position: relative;
    left: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    z-index: 3;

    .duck {
      position: fixed;
      bottom: -200px;
      left: calc(50% - 450px);
      width: 900px;
      box-shadow: 0px, 4px, 4px, 0px rgba(0, 0, 0, 0.75);
    }
  }

  .content {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: calc(100% - 40px);
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 11;
    opacity: 0;
    transform: scale(1.2);
    filter: blur(4px);
    transition: all 1.5s ease-in-out;

    .texts {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 35%;
      width: calc(100% - 40px);
      max-width: calc(1360px - 40px);
      min-height: 100vh;
      padding: 20px;
      text-align: center;

      p {
        position: absolute;
        width: calc(100% - 40px);
        margin-top: 100px;
        font-size: 25px;
        line-height: 40px;
        transition: all 0.3s ease-in-out;
      }

      .visible {
        visibility: visible;
        opacity: 1;
      }

      .invisible {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  .show-content {
    opacity: 1;
    transform: scale(1);
    filter: blur(0px);
  }
}

@media only screen and (max-width: 1024px) {
  .values {
    min-height: 350vh;
    .content {
      .texts {
        top: 130px;

        p {
          margin-top: 150px;
          font-size: 16px;
          line-height: 35px;
          text-align: justify;
        }
      }
    }
  }
}
</style>
