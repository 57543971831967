import { createStore } from "vuex";
import NavigationEnum from "@/enums/NavigationEnum.js";

export default createStore({
  state: {
    currentPart: NavigationEnum.Home,
    currentPartView: NavigationEnum.Home,
  },
  getters: {
    currentPartPage: function (state) {
      return state.currentPart;
    },
    currentPartPageView: function (state) {
      return state.currentPartView;
    },
  },
  mutations: {
    UPDATE_CURRENT_PART(state, payload) {
      state.currentPart = payload;
    },
    UPDATE_CURRENT_PART_VIEW(state, payload) {
      state.currentPartView = payload;
    },
  },
  actions: {
    setCurrentPart(context, payload) {
      context.commit("UPDATE_CURRENT_PART", payload);
    },
    setCurrentPartView(context, payload) {
      context.commit("UPDATE_CURRENT_PART_VIEW", payload);
    },
  },
  modules: {},
});
